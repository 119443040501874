import { DatePipe } from '@angular/common';
import {
	Component,
	EventEmitter,
	HostBinding,
	inject,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';
import { ViewActivityDialogComponent } from '@context/frontend/activity';
import { AlertService } from '@context/frontend/alert';
import { DialogService, RelativeTimePipe } from '@context/frontend/common';
import { MediaThumbnailComponent } from '@context/frontend/media';
import { getUaDevice, IsDevicePipe } from '@context/frontend/native/platform';
import { StorageService } from '@context/frontend/storage';
import {
	AcknowledgeDialogComponent,
	DetailMenuDialogComponent,
	DetailMenuDropdownComponent,
} from '@context/frontend/ui/components';
import {
	AuthService,
	AvatarComponent,
	UserNamePipe,
} from '@context/frontend/user';
import { ViewerService } from '@context/frontend/viewer';
import {
	Content,
	DetailMenuItem,
	hasInheritedViewAccess,
	PresetCompressions,
	ViewAccess,
} from '@context/shared/types/common';
import { ContentLog } from '@context/shared/types/log';
import {
	ContentMedia,
	getPreferredThumbnail,
} from '@context/shared/types/media';
import { hasInheritedRolePermission, User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import {
	TuiButton,
	TuiDropdown,
	TuiIcon,
	TuiLoader,
	TuiSizeL,
	TuiSizeS,
} from '@taiga-ui/core';
import { TuiBadge, TuiCarousel } from '@taiga-ui/kit';
import {
	PolymorpheusComponent,
	PolymorpheusTemplate,
} from '@taiga-ui/polymorpheus';
import { DocumentReference } from 'firebase/firestore';
import { LogFormDialogComponent } from '../../dialogs';
import { LogService } from '../../services';
import { LogThumbnailComponent } from '../log-thumbnail/log-thumbnail.component';

@Component({
	standalone: true,
	imports: [
		RelativeTimePipe,
		TranslocoModule,
		DatePipe,
		TuiIcon,
		DetailMenuDropdownComponent,
		PolymorpheusTemplate,
		IsDevicePipe,
		TuiDropdown,
		TuiBadge,
		LogThumbnailComponent,
		AvatarComponent,
		UserNamePipe,
		TuiCarousel,
		TuiLoader,
		TuiButton,
		MediaThumbnailComponent,
	],
	selector: 'ctx-log-card',
	templateUrl: 'log-card.component.html',
	styleUrl: 'log-card.component.scss',
})
export class LogCardComponent implements OnInit {
	@HostBinding('attr.device')
	readonly device = getUaDevice();

	@HostBinding('attr.size')
	@Input()
	size: TuiSizeL | TuiSizeS | 'full' = 'l';

	@HostBinding('attr.archived')
	isArchived = false;

	@Input()
	parent!: Content;

	@Input()
	parentRef!: DocumentReference<Content>;

	@Input()
	log!: ContentLog;

	@Input()
	logRef!: DocumentReference<ContentLog>;

	@Input()
	actionable = true;

	@Input()
	access: ViewAccess | null = null;

	@Output()
	readonly update = new EventEmitter<ContentLog | null>();

	readonly dialog = inject(DialogService);
	readonly logService = inject(LogService);
	readonly alert = inject(AlertService);
	readonly authService = inject(AuthService);
	readonly storageService = inject(StorageService);
	readonly route = inject(ActivatedRoute);
	readonly viewer = inject(ViewerService);

	menuOpen = false;
	collapsed = true;

	menuItems: DetailMenuItem[] = [];

	loading = false;
	author: User | null = null;
	media: ContentMedia[] = [];

	thumbnailUrl: string | null = null;

	defaultMenuItems: DetailMenuItem[] = [
		{
			text: 'action.view-history',
			iconStart: '@tui.history',
			handler: () => this.onViewHistory(),
			access: 'share',
		},
	];

	activeMenuItems: DetailMenuItem[] = [
		{
			iconStart: '@tui.pencil-line',
			iconEnd: '@tui.chevron-right',
			text: 'content.edit-details',
			handler: () => this.onEdit(),
			access: 'edit',
		},
		{
			iconStart: '@tui.archive',
			text: 'log.archive-log',
			handler: () => this.onArchive(),
			access: 'edit',
		},
	];

	archivedMenuItems: DetailMenuItem[] = [
		{
			iconStart: '@tui.archive-restore',
			text: 'content.restore-from-archive',
			handler: () => this.onUnarchive(),
			access: 'edit',
		},
	];

	readonly logDetailMenuDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(DetailMenuDialogComponent),
			DetailMenuDialogComponent.Options({
				label: 'log.manage-log',
				data: { menuItems: this.menuItems },
			}),
		);

	readonly logEditDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(LogFormDialogComponent),
			LogFormDialogComponent.Options({
				label: 'log.edit-log',
				data: {
					log: this.log,
					logRef: this.logRef,
					contentRef: this.parentRef,
				},
			}),
		);

	readonly fileActivityDialog = () =>
		this.dialog.open(
			new PolymorpheusComponent(ViewActivityDialogComponent),
			ViewActivityDialogComponent.Options({
				data: { entityRef: this.logRef },
			}),
		);

	readonly acknowledgeDialog = (
		label: string,
		description: string,
		acknowledge: string,
	) =>
		this.dialog.open(
			new PolymorpheusComponent(AcknowledgeDialogComponent),
			AcknowledgeDialogComponent.Options({
				label,
				data: {
					description,
					acknowledge,
					translationParams: { log: this.log.name },
				},
			}),
		);

	readonly logArchiveDialog = () =>
		this.acknowledgeDialog(
			'log.archive-header',
			'log.archive-description',
			'log.archive-acknowledge',
		);

	readonly logUnarchiveDialog = () =>
		this.acknowledgeDialog(
			'log.unarchive-header',
			'log.unarchive-description',
			'log.unarchive-acknowledge',
		);

	readonly logDeleteDialog = () =>
		this.acknowledgeDialog(
			'log.delete-header',
			'log.delete-description',
			'log.delete-acknowledge',
		);

	ngOnInit() {
		this.initialize();
	}

	initialize() {
		this.access = this.getAccess();
		this.isArchived = !!this.log.archivedAt;
		this.determineThumbnail();

		if (this.access !== 'view') {
			this.setupMenuItems();
		}
	}

	getAccess(): ViewAccess {
		console.log(this.access);

		const user = this.authService.user;
		if (!user) return 'view';

		if (user.id === this.log.createdBy.id) return 'edit';

		return this.access ?? 'view';
	}

	determineThumbnail() {
		const thumbnail = getPreferredThumbnail(this.log.thumbnails, {
			resolution: PresetCompressions.thumbnail.maxWidthOrHeight,
		});
		if (!thumbnail) return;

		this.storageService
			.getDownloadUrl(
				this.storageService.getReference(thumbnail.storagePath),
			)
			.then((url) => {
				this.thumbnailUrl = url;
			});
	}

	setupMenuItems() {
		if (!this.access) {
			this.menuItems = [];
			return;
		}

		const items = [];
		if (this.isArchived) {
			items.push(...this.archivedMenuItems);

			// we can only delete the folder if the user is an owner or greater
			const currentUser = this.authService.user as User;
			if (hasInheritedRolePermission(currentUser.role, 'owner')) {
				items.push({
					iconStart: '@tui.trash',
					text: 'log.delete-log',
					handler: () => this.onDelete(),
				});
			}
		} else {
			items.push(...this.activeMenuItems);
		}

		items.push(...this.defaultMenuItems);
		this.menuItems = items.filter(
			(i) =>
				!i.access ||
				hasInheritedViewAccess(this.access as ViewAccess, i.access),
		);
	}

	onLoadAuthor = () =>
		getDoc(this.log.createdBy as DocumentReference<User>).then((ref) => {
			this.author = ref.data() as User;
		});

	onLoadMedia = () =>
		Promise.all([
			...this.log.media.map((m) =>
				getDoc(m as DocumentReference<ContentMedia>).then(
					(doc) => doc.data() as ContentMedia,
				),
			),
		]).then((media) => {
			this.media = media;
		});

	onToggleCollapsed() {
		this.collapsed = !this.collapsed;

		if (
			!this.collapsed &&
			(!this.author || this.media.length !== this.log.media.length)
		) {
			this.loading = true;
			Promise.all([this.onLoadAuthor(), this.onLoadMedia()]).finally(
				() => {
					this.loading = false;
				},
			);
		}
	}

	onMenuOpen = () => this.logDetailMenuDialog().subscribe();

	onViewHistory = () => this.fileActivityDialog().subscribe();

	onView = (media: ContentMedia) => this.viewer.preview(media);

	onEdit = () =>
		this.logEditDialog().subscribe({
			next: (data) => {
				if (data) {
					this.log = Object.assign({}, this.log, data);
					this.initialize();
				}
			},
		});

	onArchive = () =>
		this.logArchiveDialog().subscribe({
			next: (data) => {
				if (data) {
					this.logService
						.archive(this.parentRef, this.logRef, this.log)
						.then((data) => {
							this.log = Object.assign({}, this.log, data);
							this.initialize();

							this.alert.open('generic-success').subscribe();
							this.update.emit(data as ContentLog);
						})
						.catch((error) => {
							console.error(
								'There was an issue deleting the file',
								error,
							);
							this.alert.open('generic-error').subscribe();
							throw new Error(
								'There was an issue deleting on the file',
							);
						});
				}
			},
		});

	onUnarchive = () =>
		this.logUnarchiveDialog().subscribe({
			next: (data) => {
				if (data) {
					this.logService
						.unarchive(this.parentRef, this.logRef, this.log)
						.then((data) => {
							this.log = Object.assign({}, this.log, data);
							this.initialize();

							this.alert.open('generic-success').subscribe();
							this.update.emit(data as ContentLog);
						})
						.catch((error) => {
							console.error(
								'There was an issue deleting the file',
								error,
							);
							this.alert.open('generic-error').subscribe();
							throw new Error(
								'There was an issue deleting on the file',
							);
						});
				}
			},
		});

	onDelete = () =>
		this.logDeleteDialog().subscribe({
			next: (data) => {
				if (data) {
					this.logService
						.delete(this.parent, this.logRef, this.log)
						.then(() => {
							this.alert.open('generic-success').subscribe();
							this.update.emit(null);
						})
						.catch((error) => {
							console.error(
								'There was an issue deleting the file',
								error,
							);
							this.alert.open('generic-error').subscribe();
							throw new Error(
								'There was an issue deleting on the file',
							);
						});
				}
			},
		});
}
