import { AsyncPipe } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@context/frontend/alert';
import { DialogBase, DialogOptions } from '@context/frontend/common';
import { ContentRowComponent } from '@context/frontend/content';
import { applyValidators } from '@context/frontend/form';
import { StorageService } from '@context/frontend/storage';
import { AuthService } from '@context/frontend/user';
import { Content } from '@context/shared/types/common';
import { ContentLog } from '@context/shared/types/log';
import {
	ContentMedia,
	ExistingFile,
	FileState,
} from '@context/shared/types/media';
import { User, UserOption } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiAutoFocus } from '@taiga-ui/cdk';
import {
	TuiButton,
	TuiError,
	TuiIcon,
	TuiLoader,
	TuiTextfield,
} from '@taiga-ui/core';
import {
	TuiButtonLoading,
	TuiFieldErrorPipe,
	TuiFileLike,
	TuiFiles,
	TuiInputFiles,
} from '@taiga-ui/kit';
import {
	TuiTextareaModule,
	TuiTextfieldControllerModule,
} from '@taiga-ui/legacy';
import { DocumentReference } from 'firebase/firestore';
import { LogService } from '../../services';

type LogFormDialogOptions = {
	content?: Content;
	contentRef: DocumentReference<Content>;
	log?: ContentLog;
	logRef?: DocumentReference<ContentLog>;
	contentPreview?: boolean;
};

@Component({
	standalone: true,
	imports: [
		ReactiveFormsModule,
		TuiTextfield,
		TranslocoModule,
		TuiFieldErrorPipe,
		TuiError,
		AsyncPipe,
		TuiTextareaModule,
		TuiTextfieldControllerModule,
		TuiButton,
		TuiButtonLoading,
		TuiFiles,
		TuiIcon,
		TuiInputFiles,
		AsyncPipe,
		TuiLoader,
		TuiAutoFocus,
		ContentRowComponent,
	],
	selector: 'ctx-log-form-dialog',
	templateUrl: 'log-form-dialog.component.html',
	styleUrl: 'log-form-dialog.component.scss',
})
export class LogFormDialogComponent
	extends applyValidators(DialogBase<ContentLog | null, LogFormDialogOptions>)
	implements OnInit
{
	static override readonly DialogOptions = {
		label: 'log.create-log',
		size: 's',
		dismissible: false,
	} as DialogOptions;

	private readonly authService = inject(AuthService);
	private readonly logService = inject(LogService);
	private readonly alert = inject(AlertService);
	private readonly storageService = inject(StorageService);

	form: FormGroup | null = null;
	existingFiles: ExistingFile[] = [];

	busy = false;
	loading = false;

	ngOnInit() {
		this.setupForm();
	}

	async setupForm() {
		this.loading = true;
		const defaultName = new Date().toLocaleDateString();
		this.form = new FormGroup({
			id: new FormControl(null),
			name: new FormControl(defaultName, (field) =>
				this.getRequiredValidator(field, 'form.error.name.required'),
			),
			files: new FormControl([], (field) =>
				this.getMaxLengthValidator(field, 10),
			),
			thumbnails: new FormControl([]),
			description: new FormControl(null, (field) =>
				this.getRequiredValidator(
					field,
					'form.error.log-entry.required',
				),
			),
			createdBy: new FormControl(
				new UserOption(
					this.authService.user as User,
					this.authService.userRef as DocumentReference<User>,
				),
			),
		});

		if (this.context.data.log) {
			const createdBySnapshot = await getDoc(
				this.context.data.log.createdBy as DocumentReference<User>,
			);

			const mediaRef = this.context.data.log.media;
			const media = await Promise.all(
				mediaRef.map((ref) =>
					getDoc(ref as DocumentReference<ContentMedia>).then(
						(doc) => doc.data() as ContentMedia,
					),
				),
			);

			this.form.patchValue({
				...this.context.data.log,
				createdBy: new UserOption(
					createdBySnapshot.data() as User,
					createdBySnapshot.ref,
				),
			});

			this.existingFiles = await Promise.all(
				media.map((item) =>
					this.storageService
						.getBlob(
							this.storageService.getReference(item.storagePath),
						)
						.then((blob) =>
							Object.assign(
								new File([blob], item.name, {
									type: blob.type,
								}),
								{ state: 'active' as FileState, id: item.id },
							),
						),
				),
			);
		}

		this.loading = false;
		this.form.get('createdBy')?.disable();
	}

	onSubmit() {
		this.form?.markAllAsTouched();
		if (this.busy || !this.form || this.form.invalid) return;

		this.busy = true;
		const value = this.form.getRawValue();
		this.form.disable();

		const createdByValue = value.createdBy as UserOption;
		value.createdBy = createdByValue.valueOf();

		if (this.context.data.log) {
			// pull out the removed files
			const removedFiles =
				this.existingFiles.filter((f) => f.state === 'deleted') ?? [];

			//
			value.removedMedia = [
				...this.context.data.log.media.filter((m) =>
					removedFiles.some((f) => f.id === m.id),
				),
			];

			// reapply the base media items anf filter out the removed files
			value.media = [
				...this.context.data.log.media.filter(
					(m) => !removedFiles.some((f) => f.id === m.id),
				),
			];

			return this.logService
				.update(
					this.context.data.contentRef,
					value,
					this.context.data.logRef as DocumentReference<ContentLog>,
					{ addActivityLog: true, type: 'update' },
				)
				.then((log) => {
					this.onDismiss(log as ContentLog);
					this.alert.open('generic-success').subscribe();
				})
				.catch((error) => {
					console.error('There was an issue editing the log', error);
					this.alert.open('generic-error').subscribe();
				})
				.finally(() => {
					this.busy = false;
					this.form?.enable();
					this.form?.get('createdBy')?.disable();
				});
		}

		return this.logService
			.create(this.context.data.contentRef, value)
			.then((data) => {
				this.onDismiss(data);
				this.alert.open('generic-success').subscribe();
			})
			.catch((error) => {
				console.error('There was an issue creating the log', error);
				this.alert.open('generic-error').subscribe();
			})
			.finally(() => {
				this.busy = false;
				this.form?.enable();
				this.form?.get('createdBy')?.disable();
			});
	}

	onFileRemove(file: TuiFileLike) {
		const control = this.form?.get('files');
		if (!control) return;

		control.setValue(
			control.value.filter((current: TuiFileLike) => current !== file) ??
				[],
		);
	}

	onExistingFileRemove(file: ExistingFile) {
		this.existingFiles.map((existing) => {
			if (existing === file) file.state = 'deleted';
			return file;
		});
	}

	onRemovedFileRestore(file: ExistingFile) {
		this.existingFiles.map((existing) => {
			if (existing === file) file.state = 'active';
			return file;
		});
	}
}
